import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/graylog';
import { Input } from 'components/bootstrap';

const LDAPAdapterFieldSet = ({ config, updateConfig, handleFormEvent, validationMessage, validationState }) => {
  const isCreate = useRef(!config.user_passwd?.is_set);
  const [showResetPasswordButton, setShowResetPasswordButton] = useState(config.user_passwd?.is_set === true);

  const setUserPassword = useCallback((nextUserPassword) => {
    updateConfig({ ...config, user_passwd: nextUserPassword });
  }, [updateConfig, config]);

  useEffect(() => {
    // Set a default value on `user_passwd` that the server can deserialize
    if (config.user_passwd?.is_set !== undefined) {
      // Keeping value is only helpful when editing, but since setting '' as value throws an error during
      // validation, this at least avoids users seeing validation errors constantly.
      setUserPassword({ keep_value: true });
    }
  }, [setUserPassword, config.user_passwd]);

  const handleUserPasswordChange = ({ target }) => {
    const typedPassword = target.value;
    let nextPassword = { set_value: typedPassword };

    if (typedPassword === '') {
      nextPassword = { delete_value: true };
    }

    setUserPassword(nextPassword);
  };

  const toggleUserPasswordReset = () => {
    if (showResetPasswordButton) {
      setUserPassword({ delete_value: true });
      setShowResetPasswordButton(false);

      return;
    }

    setUserPassword({ keep_value: true });
    setShowResetPasswordButton(true);
  };

  return (
    <fieldset>
      <Input type="text"
             id="ldap_ip"
             name="ldap_ip"
             label="LDAP Server IP Address"
             onChange={handleFormEvent}
             help={validationMessage('ldap_ip', 'LDAP server IP address.')}
             bsStyle={validationState('ldap_ip')}
             value={config.ldap_ip}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9"
             required />
      <Input type="number"
             id="ldap_connect_timeout"
             name="ldap_connect_timeout"
             label="LDAP Connect Timeout"
             required
             onChange={handleFormEvent}
             help={validationMessage('ldap_connect_timeout', 'LDAP connection timeout in milliseconds.')}
             bsStyle={validationState('ldap_connect_timeout')}
             value={config.ldap_connect_timeout}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="number"
             id="ldap_pool_max_connections"
             name="ldap_pool_max_connections"
             label="LDAP Pool Max Connections"
             required
             onChange={handleFormEvent}
             help={validationMessage('ldap_pool_max_connections', 'The number of max connections simultaneously.')}
             bsStyle={validationState('ldap_pool_max_connections')}
             value={config.ldap_pool_max_connections}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="text"
             id="base_dn"
             name="base_dn"
             label="Base DN"
             onChange={handleFormEvent}
             help={validationMessage('base_dn', 'The DN of the LDAP server')}
             bsStyle={validationState('base_dn')}
             value={config.base_dn}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9"
             required />
      <Input type="text"
             id="user_dn"
             name="user_dn"
             label="User DN"
             onChange={handleFormEvent}
             help={validationMessage('CN=Administrator', 'The DN of the user who is authorized to bind to the LDAP Server. (e.g CN=Administrator)')}
             bsStyle={validationState('user_dn')}
             value={config.user_dn}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9"
             required />
      {showResetPasswordButton ? (
        <Input id="user_passwd"
               label="User Password"
               labelClassName="col-sm-3"
               wrapperClassName="col-sm-9">
          <Button onClick={toggleUserPasswordReset}>Reset password</Button>
        </Input>
      ) : (
        <Input type="password"
               id="user_passwd"
               name="user_passwd"
               label="User Password"
               buttonAfter={!isCreate.current ? (
                 <Button type="button" onClick={toggleUserPasswordReset}>
                   Undo Reset
                 </Button>
               ) : undefined}
               onChange={handleUserPasswordChange}
               help={validationMessage('user_passwd', 'The password of the user who is authorized to bind to the LDAP Server.')}
               bsStyle={validationState('user_passwd')}
               value={config.user_passwd?.set_value || ''}
               labelClassName="col-sm-3"
               wrapperClassName="col-sm-9"
               required />
      )}

      <Input type="number"
             id="ldap_port"
             name="ldap_port"
             label="LDAP Port"
             required
             onChange={handleFormEvent}
             help={validationMessage('ldap_port', 'The port to bind to the LDAP server.')}
             bsStyle={validationState('ldap_port')}
             value={config.ldap_port}
             labelClassName="col-sm-3"
             wrapperClassName="col-sm-9" />
      <Input type="checkbox"
             id="tls"
             name="tls"
             label="Enable TLS"
             checked={config.tls}
             onChange={handleFormEvent}
             wrapperClassName="col-md-offset-3 col-md-9" />
      <Input type="checkbox"
             id="verify_certificate"
             name="verify_certificate"
             label="Verify Certificates"
             checked={config.verify_certificate}
             onChange={handleFormEvent}
             wrapperClassName="col-md-offset-3 col-md-9" />
    </fieldset>
  );
};

LDAPAdapterFieldSet.propTypes = {
  config: PropTypes.shape({
    ldap_ip: PropTypes.string.isRequired,
    base_dn: PropTypes.string.isRequired,
    ldap_connect_timeout: PropTypes.number.isRequired,
    user_dn: PropTypes.string.isRequired,
    user_passwd: PropTypes.object.isRequired,
    ldap_port: PropTypes.number.isRequired,
    tls: PropTypes.bool.isRequired,
    verify_certificate: PropTypes.bool.isRequired,
    ldap_pool_max_connections: PropTypes.number,
  }).isRequired,
  updateConfig: PropTypes.func.isRequired,
  handleFormEvent: PropTypes.func.isRequired,
  validationState: PropTypes.func.isRequired,
  validationMessage: PropTypes.func.isRequired,
};

export default LDAPAdapterFieldSet;
