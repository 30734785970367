import type { AzureGenericInputCreateRequest, AzureLogsInputCreateRequest, FormDataType } from '../types';

export const toAzureLogsInputCreateRequest = ({
  eventHubName,
  connectionString,
  azureName,
  azureThrottleEnabled,
  azureBatchSize,
  azureWaitTime,
  pollingInterval,
  consumerGroup,
}: FormDataType): AzureLogsInputCreateRequest => ({
  name: azureName.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  azure_event_hub_name: eventHubName.value,
  enable_throttling: !!azureThrottleEnabled?.value,
  azure_batch_size: azureBatchSize.value,
  azure_wait_time: azureWaitTime.value,
  azure_connection_string: connectionString.value,
  azure_consumer_group: consumerGroup.value,
});

export const toGenericInputCreateRequest = ({
  eventHubName,
  connectionString,
  azureName,
  azureThrottleEnabled,
  azureBatchSize,
  azureWaitTime,
  pollingInterval,
  consumerGroup,
}: FormDataType): AzureGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.azure.AzureLogsInput',
  title: azureName.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    event_hub_name: eventHubName.value,
    throttling_allowed: !!azureThrottleEnabled?.value,
    azure_batch_size: azureBatchSize.value,
    azure_wait_time: azureWaitTime.value,
    connection_string: connectionString.value,
    consumer_group: consumerGroup.value,
  },
});
