import React from 'react';
import { PropTypes } from 'prop-types';

import PageHeader from 'components/common/PageHeader';

import EmbeddedO365App from './EmbeddedO365App';

const ExternalLink = ({ href, title }) => <a href={href} rel="noopener noreferrer" target="_blank">{title}</a>;

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const O365App = () => {
  return (
    <>
      <PageHeader title="Office 365 Integrations">
        <span>This feature retrieves log records from Office 365.</span>
        <p>
          You need to have <ExternalLink href="https://portal.azure.com/#blade/" title="Azure AD" />,{' '}
          <ExternalLink href="https://www.office.com/" title="Office 365" /> subscriptions and have turned on{' '}
          <ExternalLink href="https://protection.office.com/unifiedauditlog" title="unified logging" />.
        </p>
      </PageHeader>
      <EmbeddedO365App />
    </>
  );
};

O365App.propTypes = {};

export default O365App;
